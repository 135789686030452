export const ADD_TO_CART = 'ADD_TO_CART';
export const SET_COSTUMER_TOKEN = 'SET_COSTUMER_TOKEN';
export const SET_COSTUMER_DATA = 'SET_COSTUMER_DATA';
export const LOGOUT = 'LOGOUT';
export const SYNC_CART = 'SYNC_CART';
export const RESET_CART = 'RESET_CART';
export const SET_QUOTE_ID = 'SET_QUOTE_ID';
export const SET_LOADING_CHECKOUT_FALSE = 'SET_LOADING_CHECKOUT_FALSE';
export const SET_LOADING_CHECKOUT_TRUE = 'SET_LOADING_CHECKOUT_TRUE'; 
export const SET_SOMA_VALOR_PRODUTOS_CARRINHO = 'SET_SOMA_VALOR_PRODUTOS_CARRINHO';
export const LOGIN = 'LOGIN';
export const SET_LOADING_TRUE = 'SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'SET_LOADING_FALSE';
export const DELETE_ITEM_FROM_CART = 'DELETE_ITEM_FROM_CART';
export const SET_BILLET_URL = 'SET_BILLET_URL';
export const INCREMENT_PRODUCT_QTY = 'INCREMENT_PRODUCT_QTY';
export const DECREMENT_PRODUCT_QTY = 'DECREMENT_PRODUCT_QTY';
export const ADD_TO_WISHLIST = 'ADD_TO_WISHLIST';
export const REMOVE_FROM_WISHLIST = 'REMOVE_FROM_WISHLIST';
export const SET_WISHLIST = 'SET_WISHLIST';
export const TOGGLE_CART = 'TOGGLE_CART';
export const TOGGLE_CUSTOM_OPTION = 'TOGGLE_CUSTOM_OPTION';
export const TOGGLE_CUSTOM_OPTIONS_ALERT = 'TOGGLE_CUSTOM_OPTIONS_ALERT';
export const TOGGLE_RASTREIO_ALERT = 'TOGGLE_RASTREIO_ALERT';
export const TOGGLE_LOGIN_ALERT = 'TOGGLE_LOGIN_ALERT';
export const CREATE_NEW_CART = 'CREATE_NEW_CART';
export const CHANGE_CUSTOMER_DATA = 'CHANGE_CUSTOMER_DATA';
export const LOGIN_USER_DRAWER = 'LOGIN_USER_DRAWER';
export const TOGGLE_DRAWER_LEFT = 'TOGGLE_DRAWER_LEFT';
export const TOGGLE_DRAWER_RIGHT = 'TOGGLE_DRAWER_RIGHT';
export const TOGGLE_DRAWER_LOGIN = 'TOGGLE_DRAWER_LOGIN';
export const TOGGLE_CHANGE_PASSWORD = 'TOGGLE_CHANGE_PASSWORD';
export const TOGGLE_EDIT_ADDRESS = 'TOGGLE_EDIT_ADDRESS';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const CLOSE_ALERT = 'CLOSE_ALERT';
export const GLOBAL_ALERT = 'GLOBAL_ALERT';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';