import * as actionTypes from "./actions/actionTypes"

const initialState = {
  cart: [],
  userAuth: {
    auth: false,
    token: "",
  },
  userData: {},
  quoteId: null,
  isLoadingCheckout: true,
  isLoading: false,
  totalPrice: 0,
  billetUrl: "",
  swatchFilter: [],
  wishlist: [],
  drawers: {
    isDrawerLeftOpen: false,
    isDrawerRightOpen: false,
    isCartOpen: true,
    isCategoryOpen: true,
    isLoginOpen: false,
    isMeasuringTechniquesOpen: false
  },
  customOptionOpen: false,
  isAlertOpen: false,
  alertMessage: null,
  alertButton: null,
  rastreioAlert: false,
  customOptionsAlert: false,
  loginAlert: false,
  successOrderNumber: 0,
  myAccount: {
    openChangePassword: false,
    openEditAddress: false,
    openOrder: false,
    savingIn: '',
    selectedOrder: null
  },
  isSearchOpen: false
}

const reducer = (state = initialState, action) => {

  switch (action.type) {
    case actionTypes.ADD_TO_CART: {
      const newCart = [...state.cart]
      const newTotalPrice =
        state.totalPrice +
        action.payload.visibleCartItem.price *
        +action.payload.visibleCartItem.qty

      const index = newCart.findIndex(
        x =>
          x.visibleCartItem.id === action.payload.visibleCartItem.id &&
          JSON.stringify(x.visibleCartItem.custom_options) ===
          JSON.stringify(action.payload.visibleCartItem.custom_options)
      )
      if (index === -1) {
        newCart.push({
          visibleCartItem: action.payload.visibleCartItem,
          magentoCartItem: action.payload.magentoCartItem,
        })
      } else {
        newCart[index].visibleCartItem.qty++
        newCart[index].magentoCartItem.cartItem.qty++
      }

      return {
        ...state,
        cart: newCart,
        cartOpen: true,
        totalPrice: newTotalPrice,
      }
      //return state;
    }
    case actionTypes.TOGGLE_CHANGE_PASSWORD: {

      const updatedMyAccount = { ...state.myAccount }
      updatedMyAccount.openChangePassword = action.payload.changePasswordOpen;

      return {
        ...state,
        myAccount: updatedMyAccount
      }
    }
    case actionTypes.TOGGLE_EDIT_ADDRESS: {

      const updatedMyAccount = { ...state.myAccount }
      updatedMyAccount.openEditAddress = action.payload.editAddressOpen;

      return {
        ...state,
        myAccount: updatedMyAccount
      }
    }
    case actionTypes.LOGOUT: {
      return {
        ...state,
        userAuth: {
          auth: false,
          token: "",
        },
        cart: [],
        wishlist: [],
        userData: {}
      }
    }
    case actionTypes.SET_SELECTED_ORDER: {
      const copyMyAccount = { ...state.myAccount };
      copyMyAccount.selectedOrder = action.payload.selectedOrder;

      return {
        ...state,
        myAccount: copyMyAccount
      }
    }
    case actionTypes.SAVE_USER_DATA: {
      console.log(action.payload.userData)
      return {
        ...state,
        userData: { ...action.payload.userData },
        isLoading: false
      }
    }
    case actionTypes.TOGGLE_DRAWER_LEFT: {
      //console.log('ok')
      let copyDrawers;
      //Se o conteúdo do drawer de login estiver aberto, ele somente mostra o conteúdo de categoria e fecha o conteúdo de login
      if (state.drawers.isLoginOpen === true) {
        copyDrawers = { ...state.drawers, isCategoryOpen: true, isLoginOpen: false }
      } else {
        copyDrawers = { ...state.drawers, isDrawerLeftOpen: !state.drawers.isDrawerLeftOpen }
      }
      //Caso contrário, se somente o conteúdo de categoria estiver aberto, ele simplesmente fecha o drawer esquerdo
      return {
        ...state,
        drawers: copyDrawers,
      }
    }
    case actionTypes.TOGGLE_DRAWER_LOGIN: {
      //Sempre quando o drawer de login é fechado, o drawer de categoria é aberto e vice e versa
      const copyDrawers = { ...state.drawers, isLoginOpen: !state.drawers.isLoginOpen, isCategoryOpen: !state.drawers.isCategoryOpen }

      return {
        ...state,
        drawers: copyDrawers,
        isLoading: false
      }
    }
    case actionTypes.TOGGLE_DRAWER_RIGHT: {

      let copyDrawers;
      copyDrawers = { ...state.drawers, isDrawerRightOpen: !state.drawers.isDrawerRightOpen }

      //Caso contrário, se somente o conteúdo de categoria estiver aberto, ele simplesmente fecha o drawer esquerdo
      return {
        ...state,
        drawers: copyDrawers,
      }
    }
    case actionTypes.CHANGE_CUSTOMER_DATA: {
      return {
        ...state,
        userData: action.payload.userData,
      }
    }
    case actionTypes.INCREMENT_PRODUCT_QTY: {
      const newCart = [...state.cart]

      newCart[action.payload.idx].magentoCartItem.cartItem.qty++
      newCart[action.payload.idx].visibleCartItem.qty++

      const newTotalPrice =
        state.totalPrice + newCart[action.payload.idx].visibleCartItem.price
      return {
        ...state,
        cart: newCart,
        totalPrice: newTotalPrice,
      }
    }
    case actionTypes.DECREMENT_PRODUCT_QTY: {
      const newCart = [...state.cart]

      newCart[action.payload.idx].magentoCartItem.cartItem.qty--
      newCart[action.payload.idx].visibleCartItem.qty--
      const newTotalPrice =
        state.totalPrice - newCart[action.payload.idx].visibleCartItem.price
      return {
        ...state,
        cart: newCart,
        totalPrice: newTotalPrice,
      }
    }
    case actionTypes.DELETE_ITEM_FROM_CART: {
      const newCart = [...state.cart]
      const newTotalPrice =
        state.totalPrice -
        newCart[action.payload.idx].visibleCartItem.price *
        +newCart[action.payload.idx].visibleCartItem.qty
      newCart.splice(action.payload.idx, 1)
      // console.log(newCart)
      return {
        ...state,
        cart: newCart,
        totalPrice: newTotalPrice,
      }
    }
    case actionTypes.LOGIN_USER_DRAWER: {
      const copyDrawers = { ...state.drawers, isLoginOpen: !state.drawers.isLoginOpen, isCategoryOpen: true }

      return {
        ...state,
        userAuth: action.payload.userAuth,
        wishlist: action.payload.wishlist,
        cart: action.payload.cart,
        quoteId: action.payload.quoteId,
        totalPrice: action.payload.totalPrice,
        userData: action.payload.userData,
        drawers: copyDrawers,
        isLoading: false
      }
    }
    case actionTypes.TOGGLE_CUSTOM_OPTION: {
      return {
        ...state,
        customOptionOpen: !state.customOptionOpen,
      }
    }
    case actionTypes.SET_LOADING_TRUE: {
      return {
        ...state,
        isLoading: true
      }
    }
    case actionTypes.SET_LOADING_FALSE: {
      return {
        ...state,
        isLoading: false
      }
    }
    case actionTypes.ADD_TO_WISHLIST: {
      const updatedWishlist = [...state.wishlist]
      const id = action.payload
      updatedWishlist.push(id)
      console.log(updatedWishlist)
      return {
        ...state,
        wishlist: updatedWishlist,
      }
    }
    case actionTypes.REMOVE_FROM_WISHLIST: {
      const updatedWishlist = [...state.wishlist]
      const id = action.payload
      const idx = updatedWishlist.indexOf(id)
      updatedWishlist.splice(idx, 1)
      return {
        ...state,
        wishlist: updatedWishlist,
      }
    }
    case actionTypes.SET_BILLET_URL: {
      return {
        ...state,
        billetUrl: action.payload.billet,
      }
    }
    case actionTypes.CREATE_NEW_CART: {
      return {
        ...state,
        cart: [],
        quoteId: action.payload.quoteId,
        successOrderNumber: action.payload.successOrderNumber,
        isLoading: false,
        totalPrice: 0,
      }
    }
    case actionTypes.GLOBAL_ALERT: {
      return {
        ...state,
        isAlertOpen: true,
        alertMessage: action.payload.message,
        alertButton: action.payload.button
      }
    }
    case actionTypes.CLOSE_ALERT: {
      return {
        ...state,
        isAlertOpen: false,
        alertMessage: null,
        alertButton: null
      }
    }
    case actionTypes.TOGGLE_SEARCH: {
      return {
        ...state,
        isSearchOpen: !state.isSearchOpen
      }
    }
    default:
      return state
  }
}

export default reducer
